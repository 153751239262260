import React, { useState } from 'react';
import { Card, Form, Button, Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DeleteFormula } from '../../../../../services/FormulaService';
import { toast } from 'react-toastify';

const ManageOtherInfo = ({
  formula,
  navigate,
  formData,
  setFormData,
  buttonIsLoading
}) => {
  const [open, setOpen] = useState(false);
  const deleteFunction = async () => {
    const response = await DeleteFormula(formula.id);
    if (response.success === true) {
      toast(`Offre supprimée`);
      navigate(`/formulas`);
    } else {
      toast('Une erreur est survenue');
    }
  };
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  return (
    <Card>
      <Card.Header as="h5">Options</Card.Header>
      <Card.Body className="bg-light">
        <Form.Check className="mb-3" id="publicPage">
          <Form.Check.Input
            type="radio"
            value="1"
            name="published"
            defaultChecked={formData.published === 1}
            onChange={handleFieldChange}
          />
          <Form.Check.Label className="mb-0 form-label fw-semi-bold">
            Publiée :
          </Form.Check.Label>
          <div className="form-text mt-0">
            L'offre est accessible depuis le site.
          </div>
        </Form.Check>
        <Form.Check className="mb-3" id="privatePage">
          <Form.Check.Input
            type="radio"
            value="0"
            name="published"
            defaultChecked={formData.published === 0}
            onChange={handleFieldChange}
          />
          <Form.Check.Label className="mb-0 form-label fw-semi-bold">
            Brouillon :
          </Form.Check.Label>
          <div className="form-text mt-0">
            L'offre est en mode brouillon et non accessible pour les
            internautes.
          </div>
        </Form.Check>
        <div className="border-dashed-bottom my-3"></div>
        <Button
          size="sm"
          variant="falcon-default"
          className="me-2"
          type="submit"
          disabled={buttonIsLoading}
        >
          Enregistrer les modifications
        </Button>
        <h6
          className="text-danger cursor-pointer mt-2 float-end"
          onClick={() => setOpen(!open)}
        >
          <small>Supprimer</small>
        </h6>
        <Collapse in={open}>
          <div className="mt-2">
            <div className="">
              <small>
                La suppresion est définitive.
                <small>
                  <Button
                    size={'sm'}
                    variant="outline-danger"
                    className="mb-1"
                    onClick={() => {
                      deleteFunction();
                    }}
                  >
                    Supprimer l'offre
                  </Button>
                </small>
              </small>
            </div>
          </div>
        </Collapse>
      </Card.Body>
    </Card>
  );
};
ManageOtherInfo.propTypes = {
  navigate: PropTypes.any,
  formula: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  buttonIsLoading: PropTypes.bool
};

export default ManageOtherInfo;
