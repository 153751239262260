import React, { useEffect, useState } from 'react';
import ReactFlow, {
  addEdge,
  Controls,
  Background,
  useEdgesState
} from 'react-flow-renderer';
import { Col, Row } from 'react-bootstrap';
import getLayoutedElements from './FormatNodes';
import { toast } from 'react-toastify';
import {
  IndexAnswereSimulator,
  ShowQuestionSimulator
} from '../../../../../services/SimulatorService';
import { IndexFormula } from '../../../../../services/FormulaService';
import Manage from './Manage';
import PropTypes from 'prop-types';

const OverviewFlow = ({ simulator }) => {
  const [showManage, setShowManage] = useState(false);
  const [selectedNode, setSelectedNode] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [nodes, setNodes, onNodesChange] = useEdgesState([]);
  const [nodesApi, setNodesApi] = useState([]);
  const [formulasList, setFormulasList] = useState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = params => setEdges(eds => addEdge(params, eds));

  useEffect(() => {
    const fetchData = async () => {
      const response = await IndexAnswereSimulator(simulator.id);
      if (response.success === true && response.data) {
        let responseNodes = [];
        let responseEdges = [];
        setNodesApi(response.data);
        response.data.map(question => {
          responseNodes.push({
            id: `${question.id}`,
            data: {
              label: (
                <span>
                  {question.simulator_entry ? (
                    <span>
                      <b>Entrée du simulateur</b>
                      <br />
                      {question.name}
                    </span>
                  ) : (
                    question.name
                  )}
                </span>
              )
            },
            position: { x: 0, y: 0 },
            style: question.simulator_entry
              ? {
                  color: '#FFFFFF',
                  border: 'none',
                  boxShadow:
                    '0 7px 14px 0 rgba(65,69,88,0.1),0 3px 6px 0 rgba(0,0,0,0.07)',
                  width: 180,
                  background: '#00394d'
                }
              : {
                  border: 'none',
                  boxShadow:
                    'rgb(65 69 88 / 10%) 0px 0px 14px 5px, rgb(0 0 0 / 7%) 0px 3px 6px 0px',
                  width: 180
                }
          });
          question.answeres.map(answer => {
            if (answer.formulas && answer.formulas.length > 0) {
              responseNodes.push({
                id: `f-${answer.id}`,
                data: {
                  type: 'formula',
                  parent: answer.parent_question_id,
                  label: (
                    <span style={{ textAlign: 'left', display: 'block' }}>
                      <b>
                        Offre{answer.formulas.length > 1 && 's'} proposée
                        {answer.formulas.length > 1 && 's'} :
                      </b>
                      <br />
                      <ul
                        style={{
                          paddingLeft: 12,
                          marginBottom: 0,
                          marginTop: 5
                        }}
                      >
                        {answer.formulas.map((formula, index) => {
                          return <li key={index}>{formula.title}</li>;
                        })}
                      </ul>
                    </span>
                  )
                },
                position: { x: 0, y: 0 },
                style: {
                  color: '#FFFFFF',
                  border: 'none',
                  boxShadow:
                    '0 7px 14px 0 rgba(65,69,88,0.1),0 3px 6px 0 rgba(0,0,0,0.07)',
                  width: 180,
                  background: '#00D27A'
                }
              });
              responseEdges.push({
                id: `e-${answer.id}`,
                source: `${answer.parent_question_id}`,
                target: `f-${answer.id}`,
                label: `${answer.name}`,
                type: 'smoothstep',
                animated: true
              });
            } else {
              responseEdges.push({
                id: `e-${answer.id}`,
                source: `${answer.parent_question_id}`,
                target: `${answer.children_question_id}`,
                label: `${answer.name}`,
                type: 'smoothstep',
                animated: true
              });
            }
          });
        });
        const { nodes: layoutedNodes, edges: layoutedEdges } =
          getLayoutedElements(responseNodes, responseEdges);
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchFormulas = async () => {
      const response = await IndexFormula('complementaires');
      if (response.success === true && response.data) {
        let formulas = [];
        response.data.map(formula => {
          formulas.push({
            value: `${formula.id}`,
            label: formula.title
          });
        });
        setFormulasList(formulas);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchData();
    fetchFormulas();
  }, [trigger]);

  const handlemanageShow = (e, node) => {
    let id = node.id;
    if (
      node.data &&
      node.data.type &&
      node.data.type === 'formula' &&
      node.data.parent
    ) {
      id = node.data.parent;
    }
    const fetchData = async () => {
      const response = await ShowQuestionSimulator(simulator.id, id);
      if (response.success === true && response.data) {
        return response.data;
      }
    };
    fetchData().then(data => {
      setSelectedNode(data);
      setShowManage(true);
    });
  };

  return (
    <Col lg={12}>
      <Row>
        <Col lg={12} className="text-end">
          <Manage
            setTrigger={setTrigger}
            simulator={simulator}
            nodesApi={nodesApi}
            formulas={formulasList}
            showManage={showManage}
            setShowManage={setShowManage}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        </Col>
        <Col lg={12} style={{ height: '80vh', marginTop: 20 }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeClick={handlemanageShow}
            onConnect={onConnect}
            //fitView
            attributionPosition="top-right"
          >
            <Controls />
            <Background color="#aaa" gap={16} />
          </ReactFlow>
        </Col>
      </Row>
    </Col>
  );
};

export default OverviewFlow;

OverviewFlow.propTypes = {
  simulator: PropTypes.object
};
