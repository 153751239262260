import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ShowSimulator } from '../../../../../services/SimulatorService';
import OverviewFlow from './Nodes';
import PageHeader from '../../../../common/PageHeader';

const SimulatorIndex = () => {
  const [simulator, setSimulator] = useState(false);
  const [trigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ShowSimulator('offers');
      if (response.success === true) {
        setSimulator(response.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    setIsLoading(true);
    fetchData();
  }, [trigger]);

  return (
    <>
      <PageHeader
        title={`Gestion du module "Estimer mon site"`}
        description={`Gérez ici le module "Estimer mon site". Créez des nouvelles questions, associez-y des réponses menant vers de nouvelles questions ou des offres paramétrées depuis la section "Gestion des offres". Vos modifications sont automatiquement enregistrées en mode brouillon. Pour publier ce module sur le site, cliquez sur le bouton "Publier les modifications".`}
        className="mb-3"
      />
      <Card>
        {isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Card.Body className="overflow-hidden">
            <Row className="align-items-center justify-content-between">
              <Col className="ps-lg-4 my-1 text-center text-lg-start">
                <Row className="mb-4">
                  <OverviewFlow simulator={simulator} />
                </Row>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default SimulatorIndex;
