import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import AdvanceTableSearchBox from '../../../global/tables/AdvanceTableSearchBox';
import AdvanceTableFooter from '../../../global/tables/AdvanceTableFooter';
import AdvanceTableWrapper from '../../../../common/advance-table/AdvanceTableWrapper';
import { IndexFormula } from '../../../../../services/FormulaService';
import { toast } from 'react-toastify';
import IndexTable from './table';
import * as Moment from 'moment';
import PropTypes from 'prop-types';
import BulkActions from './BulkActions';
import PageHeader from '../../../../common/PageHeader';

export default function FormulasIndex({ category }) {
  const [formulas, setFormulas] = useState('');
  const [trigger, setTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const cols = [
    {
      id: 'title',
      Header: `Nom de l'offre`,
      accessor: d => {
        return d.title;
      }
    },
    {
      id: 'price',
      Header: `Prix`,
      accessor: d => {
        return d.price;
      }
    },
    {
      id: 'published',
      Header: 'Statut',
      accessor: d => {
        if (d.published === 1) {
          return 'Publiée';
        } else {
          return 'Brouillon';
        }
      }
    },
    {
      id: 'post_date',
      Header: 'Date de création',
      accessor: d => {
        return Moment(d.created_at).local().format('DD/MM/YYYY');
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await IndexFormula();
      if (response.success === true) {
        setFormulas(response.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    setIsLoading(true);
    fetchData();
  }, [`${category}${trigger}`]);

  return (
    <>
      <PageHeader
        title={`Gestion des offres`}
        description={`Gérez ici l'ensemble des offres proposées sur le simulateur. Vous pouvez ajouter une nouvelle offre, renseigner les données associées (titre, description, logo...) et choisir de la publier ou non (mode brouillon).`}
        className="mb-3"
      />
      <Card>
        <Card.Body className="overflow-hidden">
          <Row className="align-items-center justify-content-between">
            <Col className="ps-lg-4 my-1 text-center text-lg-start">
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <AdvanceTableWrapper
                  columns={cols}
                  data={formulas}
                  sortable
                  pagination
                  perPage={10}
                >
                  <Row className="flex-end-center mb-3">
                    <Col xs={9} sm={8} lg={8}>
                      <AdvanceTableSearchBox table />
                    </Col>
                    <BulkActions table setTrigger={setTrigger} />
                  </Row>
                  <IndexTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      bordered: true,
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                    setTrigger={setTrigger}
                  />
                  <div className="mt-3">
                    <AdvanceTableFooter
                      rowCount={formulas.length}
                      table
                      rowInfo
                      navButtons
                    />
                  </div>
                </AdvanceTableWrapper>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

FormulasIndex.propTypes = {
  created_at: PropTypes.any,
  category: PropTypes.string
};
