import React from 'react';
import { Col } from 'react-bootstrap';
import Create from '../Create';
import PropTypes from 'prop-types';

const BulkActions = () => {
  return (
    <Col xs={3} sm={4} lg={4} className="text-end">
      <div id="orders-actions">
        <Create />
      </div>
    </Col>
  );
};

export default BulkActions;

BulkActions.propTypes = {
  setTrigger: PropTypes.any,
  category: PropTypes.string
};
