import React from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ManageDetails = ({ formData, setFormData }) => {
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Détails de l'offre</Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="eventTitle">
              <Form.Label>Nom de l'offre</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Nom"
                onChange={handleFieldChange}
                value={formData.title}
              />
            </Form.Group>
            <Form.Group
              className="mb-3  mt-4"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={formData.content || ''}
                onChange={handleFieldChange}
                name="content"
                rows={3}
              />
            </Form.Group>
            <Form.Group
              className="mb-3  mt-4"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Prix</Form.Label>
              <InputGroup className="">
                <Form.Control
                  type="text"
                  name="price"
                  placeholder="Prix en €"
                  onChange={handleFieldChange}
                  value={formData.price}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ManageDetails.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  formula: PropTypes.object
};
export default ManageDetails;
