import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Dashboard from '../components/qwenty/pages/dashboards';
import AppContext from 'context/Context';

/*
  Qwenty
 */
import LoginMnspf from '../components/qwenty/pages/authentication/Login';
import ForgetPassword from '../components/qwenty/pages/authentication/ForgetPassword';
import PasswordReset from '../components/qwenty/pages/authentication/PasswordReset';

import FormulasIndex from '../components/qwenty/pages/formulas/index';
import UsersIndex from '../components/qwenty/pages/users/index';
import FormulasShow from '../components/qwenty/pages/formulas/manage/Manage';
import SimulatorIndex from '../components/qwenty/pages/simulators/common';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        <Route element={<AuthSimpleLayout />}>
          <Route path="login" element={<LoginMnspf />} />
          <Route path="forgot-password" element={<ForgetPassword />} />
          <Route path="reset-password" element={<PasswordReset />} />
        </Route>

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />

          {/* OFFRES */}
          <Route path="/formulas" element={<FormulasIndex />} />
          <Route path="/formulas/create" element={<FormulasShow />} />
          <Route path="/formulas/:id" element={<FormulasShow />} />

          {/* COMPLÉMENTAIRES */}
          <Route path="/simulators" element={<SimulatorIndex />} />

          <Route path="/users" element={<UsersIndex />} />
        </Route>

        {/* //--- MainLayout end  */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_CENTER}
      />
    </>
  );
};

export default Layout;
