import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Offcanvas,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../../global/forms/FormAlert';
import { toast } from 'react-toastify';
import IconButton from '../../../../common/IconButton';
import {
  DeleteQuestionSimulator,
  PostAnswerSimulator,
  PostQuestionSimulator,
  PublishSimulator,
  PutAnswerSimulator,
  PutQuestionSimulator
} from '../../../../../services/SimulatorService';
import Select from 'react-select';

export default function Manage({
  setTrigger,
  simulator,
  nodesApi,
  formulas,
  showManage,
  setShowManage,
  selectedNode,
  setSelectedNode
}) {
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [answeresForm, setAnsweresForm] = useState([]);
  const [showPublish, setShowPublish] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    simulator_id: simulator.id
  });

  const handleOpen = () => {
    if (selectedNode) {
      setFormData({
        name: selectedNode.name,
        simulator_id: simulator.id
      });
      if (selectedNode.name) {
        setShowStep2(true);
      }
      if (selectedNode.answeres.length) {
        let list = [...answeresForm];
        selectedNode.answeres.map(answer => {
          let formulasArray = [];
          if (answer.formulas && answer.formulas.length > 0) {
            answer.formulas.map(formula => {
              formulasArray.push({
                label: formula.title,
                value: formula.id
              });
            });
          }
          list.push({
            name: answer.name,
            answer_type: answer.children_question_id ? 'question' : 'formula',
            children_question_id: answer.children_question_id,
            formulas: formulasArray
          });
        });
        setAnsweresForm(list);
      }
      setShowStep3(true);
    }
  };

  const handleClose = () => {
    setShowManage(false);
    setShowPublish(false);
    setFormData({
      name: '',
      simulator_id: simulator.id
    });
    setAnsweresForm([]);
    setSelectedNode(false);
    setShowStep2(false);
    setShowStep3(false);
  };

  const publishModule = async () => {
    let responsePublishSimulator = await PublishSimulator(simulator.id);
    if (responsePublishSimulator.success === true) {
      toast('Module publié sur le site');
      setShowPublish(!showPublish);
      setTrigger(Date.now());
    }
  };

  const submit = async e => {
    e.preventDefault();
    let responseQuestion = false;
    if (selectedNode) {
      responseQuestion = await PutQuestionSimulator(
        simulator.id,
        selectedNode.id,
        formData
      );
    } else {
      responseQuestion = await PostQuestionSimulator(simulator.id, formData);
    }

    setErrorForm(responseQuestion.validator);
    if (responseQuestion.success === true) {
      let responseAnsweres = false;
      if (selectedNode) {
        responseAnsweres = await PutAnswerSimulator(
          simulator.id,
          responseQuestion.data[0].id,
          answeresForm
        );
      } else {
        responseAnsweres = await PostAnswerSimulator(
          simulator.id,
          responseQuestion.data[0].id,
          answeresForm
        );
      }
      setErrorForm(responseAnsweres.validator);
      if (responseAnsweres.success === true) {
        toast('Question enregistrée');
        setShowManage(!showManage);
        setFormData({
          name: '',
          simulator_id: simulator.id
        });
        setAnsweresForm([]);
        setShowStep2(false);
        setShowStep3(false);
        setTrigger(Date.now());
      }
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleanswerChange = (e, index) => {
    const list = [...answeresForm];
    if (e.target.name === 'name') {
      list[index].name = e.target.value;
      checkShowStep3(list);
    } else if (e.target.name === 'children_question_id') {
      list[index].children_question_id = e.target.value;
      checkShowStep3(list);
    } else if (e.target.type === 'radio') {
      list[index].answer_type = e.target.value;
      list[index].children_question_id = null;
      list[index].formulas = [];
      checkShowStep3(list);
    } else if (e.target.name === 'formulas') {
      list[index].formulas = e.target.value;
    }
    setAnsweresForm(list);
  };

  const removeQuestion = async e => {
    e.preventDefault();
    const responseQuestion = await DeleteQuestionSimulator(
      simulator.id,
      selectedNode.id
    );
    if (responseQuestion.success === true) {
      toast('Question supprimée');
      setShowManage(!showManage);
      setFormData({
        name: '',
        simulator_id: simulator.id
      });
      setAnsweresForm([]);
      setShowStep2(false);
      setShowStep3(false);
      setSelectedNode(false);
      setTrigger(Date.now());
    }
  };

  const addAnswer = () => {
    const list = [...answeresForm];
    list.push({
      name: '',
      children_question_id: null,
      answer_type: 'question',
      formulas: []
    });
    setAnsweresForm(list);
    checkShowStep3(list);
  };

  const removeAnswer = index => {
    const list = [...answeresForm];
    list.splice(index, 1);
    setAnsweresForm(list);
    checkShowStep3(list);
  };

  const checkShowStep3 = list => {
    if (showStep2 && list.length > 0) {
      setShowStep3(true);
      list.map(answer => {
        if (answer.name === '') {
          setShowStep3(false);
        }
      });
    } else if (showStep2) {
      setShowStep3(true);
    } else {
      setShowStep3(false);
    }
  };

  return (
    <>
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="plus"
        transform="shrink-3"
        className=""
        onClick={() => setShowManage(true)}
      >
        <span className="d-none d-sm-inline-block ms-1">
          Ajouter une question
        </span>
      </IconButton>
      <Button
        variant="outline-primary"
        size="sm"
        transform="shrink-3"
        style={{ marginLeft: '10px' }}
        onClick={() => setShowPublish(true)}
      >
        <span className="d-none d-sm-inline-block ms-1">
          Publier les modifications
        </span>
      </Button>
      <Offcanvas
        placement={'end'}
        show={showManage}
        onShow={handleOpen}
        onHide={handleClose}
        style={{ width: 700, backgroundColor: '#EDF2F9' }}
      >
        <Offcanvas.Header closeButton={!selectedNode}>
          <Row className="align-items-center" style={{ width: '100%' }}>
            <Col>
              <Offcanvas.Title>
                {selectedNode ? 'Modifier la question' : 'Ajouter une question'}
              </Offcanvas.Title>
            </Col>
            <Col className="text-end">
              {selectedNode && (
                <IconButton
                  variant="danger"
                  icon="trash"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                  size="sm"
                  onClick={e => {
                    removeQuestion(e);
                  }}
                >
                  Supprimer la question
                </IconButton>
              )}
            </Col>
          </Row>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && <FormAlert variant="danger" data={errorForm} />}
          <Row className="g-4">
            <Col md>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Title as="div">1 - Votre question</Card.Title>
                  <Card.Text>
                    <small>
                      Renseignez d'abord la question qui sera posée sur cette
                      carte :
                    </small>
                  </Card.Text>
                  <Form.Group
                    className="mt-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Exemple : Quel type de pompier ?"
                      name="name"
                      value={formData.name}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                  {formData.name && !showStep2 && (
                    <Form.Group
                      className="mt-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Button
                        type="button"
                        size="sm"
                        onClick={() => {
                          setShowStep2(true);
                        }}
                      >
                        Suivant
                      </Button>
                    </Form.Group>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {showStep2 && formData.name && (
            <Row className="g-4 mt-0">
              <Col md>
                <Card bg="light" text="dark">
                  <Card.Body>
                    <Card.Title as="div">2 - Réponses associées</Card.Title>
                    <Card.Text>
                      <small>
                        Renseignez ci-dessous les réponses associées à la
                        question "{formData.name}". Pour chaque réponse, vous
                        devez indiquer soit la question suivante soit la ou les
                        offres associée. Si la question suivante associée à la
                        réponse n'existe pas encore, choisissez dans la liste
                        "Créer une nouvelle question". Vous pourrez la
                        configurer plus tard.
                      </small>
                    </Card.Text>
                    {answeresForm.map((answer, index) => (
                      <Row
                        key={index}
                        className="g-2 align-items-center"
                        style={{ marginTop: 15 }}
                      >
                        <Col md={12}>
                          <Card bg="light" text="dark">
                            <Card.Body>
                              <Row className="align-items-baseline">
                                <Col md={10}>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="text"
                                      placeholder="Exemple : SPP / PATS / JSP"
                                      value={answer.name}
                                      name="name"
                                      onChange={e =>
                                        handleanswerChange(e, index)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={2} className="text-center">
                                  <IconButton
                                    variant="danger"
                                    icon="trash"
                                    className="mb-1"
                                    iconAlign="right"
                                    transform="shrink-3"
                                    size="sm"
                                    onClick={() => {
                                      removeAnswer(index);
                                    }}
                                  ></IconButton>
                                </Col>
                              </Row>
                              <Col md={12} className="mt-0">
                                <Form.Label>
                                  Choisir un type de réponse :
                                </Form.Label>
                                <Form.Check
                                  type="radio"
                                  id={`defaultRadio-${index}`}
                                  label="La réponse mène vers une autre question"
                                  name={`answerType-${index}`}
                                  value="question"
                                  defaultChecked={
                                    answer.answer_type === 'question'
                                  }
                                  onChange={e => handleanswerChange(e, index)}
                                />
                                <Form.Check
                                  type="radio"
                                  id={`checkedRadio-${index}`}
                                  label="La réponse mène vers une ou plusieurs
                                    offres"
                                  name={`answerType-${index}`}
                                  value="formula"
                                  defaultChecked={
                                    answer.answer_type === 'formula'
                                  }
                                  onChange={e => handleanswerChange(e, index)}
                                />

                                {answer.answer_type === 'question' && (
                                  <FloatingLabel
                                    controlId="floatingSelectGrid"
                                    label="Question suivante :"
                                  >
                                    <Form.Select
                                      aria-label="Statut"
                                      value={
                                        answer.children_question_id
                                          ? answer.children_question_id
                                          : ''
                                      }
                                      onChange={e =>
                                        handleanswerChange(e, index)
                                      }
                                      name="children_question_id"
                                      size="sm"
                                    >
                                      <option value="">
                                        Choisir dans la liste :
                                      </option>
                                      <option value="create">
                                        - Créer une nouvelle question
                                      </option>
                                      {nodesApi.map((node, index) => {
                                        return (
                                          <option key={index} value={node.id}>
                                            - Question existante : {node.name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </FloatingLabel>
                                )}
                                {answer.answer_type === 'formula' && (
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={formulas}
                                    placeholder="Choisir une ou plusieurs offres..."
                                    isMulti
                                    name="formulas"
                                    classNamePrefix="react-select"
                                    value={answer.formulas}
                                    onChange={value => {
                                      const list = [...answeresForm];
                                      list[index].formulas = value;
                                      setAnsweresForm(list);
                                      checkShowStep3(list);
                                    }}
                                  />
                                )}
                              </Col>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    ))}
                    <Row
                      className="g-2 align-items-center"
                      style={{ marginTop: 15 }}
                    >
                      <Col md>
                        <IconButton
                          variant="primary"
                          icon="plus"
                          className="mb-1"
                          iconAlign="left"
                          transform="shrink-3"
                          size="sm"
                          onClick={() => {
                            addAnswer();
                          }}
                        >
                          Ajouter une {answeresForm.length > 0 && 'autre '}
                          réponse
                        </IconButton>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {showStep3 && formData.name && (
            <Row className="g-4 mt-0">
              <Col md>
                <Card bg="light" text="dark">
                  <Card.Body>
                    <Card.Title as="div">3 - Finalisation</Card.Title>
                    <Card.Text>
                      <small>
                        Vous avez terminé ? Cliquez sur le bouton ci-dessous
                        pour valider :
                      </small>
                    </Card.Text>
                    <Form.Group
                      className="mt-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Button
                        type="submit"
                        size="sm"
                        onClick={e => {
                          submit(e);
                        }}
                      >
                        Enregistrer la question
                      </Button>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {selectedNode && (
            <IconButton
              variant="secondary"
              icon="times"
              className="mb-1"
              iconAlign="left"
              transform="shrink-3"
              size="sm"
              style={{ marginTop: 20, float: 'right' }}
              onClick={() => setShowManage(false)}
            >
              Annuler les modifications
            </IconButton>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        placement={'end'}
        show={showPublish}
        onShow={handleOpen}
        onHide={handleClose}
        style={{ width: 700, backgroundColor: '#EDF2F9' }}
      >
        <Offcanvas.Header closeButton={!setShowPublish}>
          <Row className="align-items-center" style={{ width: '100%' }}>
            <Col>
              <Offcanvas.Title>Publier le module</Offcanvas.Title>
            </Col>
          </Row>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="">
            <Col>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Title as="div">Publier les modifications</Card.Title>
                  <Card.Text>
                    <small>
                      Le module va être mis à jour sur le site et toutes les
                      modifications seront visibles par les internautes.
                      <br />
                      Êtes-vous sûr(e) de vouloir continuer ?
                    </small>
                  </Card.Text>
                  <Form.Group
                    className="mt-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Button
                      type="button"
                      size="sm"
                      onClick={() => {
                        publishModule();
                      }}
                    >
                      Oui, publier les modifications
                    </Button>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

Manage.propTypes = {
  simulator: PropTypes.object,
  setTrigger: PropTypes.any,
  nodesApi: PropTypes.array,
  formulas: PropTypes.array,
  showManage: PropTypes.bool,
  setShowManage: PropTypes.any,
  selectedNode: PropTypes.any,
  setSelectedNode: PropTypes.any
};
