import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'simulators';

export async function ShowSimulator(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function ShowQuestionSimulator(simulator, question) {
  return modelService.ShowModel(`${model}/${simulator}/questions/${question}`);
}

export async function IndexAnswereSimulator(simulator) {
  return modelService.IndexModel(`${model}/${simulator}/questions`);
}

export async function PostQuestionSimulator(id, formData) {
  return modelService.PostModel(`${model}/${id}/questions`, formData);
}

export async function PutQuestionSimulator(simulator, question, formData) {
  return modelService.PutModel(
    `${model}/${simulator}/questions/${question}`,
    formData
  );
}

export async function DeleteQuestionSimulator(simulator, question) {
  return modelService.DeleteModel(
    `${model}/${simulator}/questions/${question}`
  );
}

export async function PostAnswerSimulator(simulator, question, formData) {
  return modelService.PostModel(
    `${model}/${simulator}/questions/${question}/answeres`,
    formData
  );
}

export async function PutAnswerSimulator(simulator, question, formData) {
  return modelService.PutModel(
    `${model}/${simulator}/questions/${question}/answeres`,
    formData
  );
}

export async function PostSimulator(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutSimulator(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteSimulator(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function PublishSimulator(id) {
  return modelService.PostModel(`${model}/${id}/publish`, {});
}
