import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import IconButton from '../../../../common/IconButton';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

const ManageHeader = ({ formData, buttonIsLoading }) => {
  let navigate = useNavigate();
  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">
              <IconButton
                className="me-2 mb-1"
                variant="falcon-default"
                size="sm"
                icon="chevron-left"
                transform="shrink-3"
                onClick={() => navigate(`/formulas`)}
              />
              {formData.title}
            </h5>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              type="submit"
              disabled={buttonIsLoading}
            >
              Enregistrer les modifications
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ManageHeader.propTypes = {
  formData: PropTypes.object,
  buttonIsLoading: PropTypes.bool
};
export default ManageHeader;
