export const dashboardRoutes = {
  label: 'Dashboard',
  children: [
    {
      name: 'Tableau de bord',
      icon: 'chart-pie',
      to: '/',
      active: true
    }
  ]
};
export const complementairesRoutes = {
  label: 'Estimer mon site',
  children: [
    {
      name: 'Gestion des offres',
      icon: 'table',
      to: '/formulas',
      active: true
    },
    {
      name: 'Estimer mon site',
      icon: 'layer-group',
      to: '/simulators',
      active: true
    }
  ]
};
export const usersRoutes = {
  label: 'Comptes',
  children: [
    {
      name: 'Administrateurs',
      icon: 'user',
      to: '/users',
      active: true
    }
  ]
};

export default [dashboardRoutes, complementairesRoutes, usersRoutes];
