import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { LoginUserService } from '../../../../services/AuthService';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import FormAlert from '../../global/forms/FormAlert';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';

const LoginMnspf = () => (
  <>
    <h5 className="text-center mb-3">Connexion à mon espace admin</h5>
    <LoginForm />
  </>
);

const LoginForm = ({ hasLabel }) => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    email: searchParams.get('email') ? searchParams.get('email') : '',
    password: '',
    'g-recaptcha-response': false
  });

  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const ReComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleSubmit = async () => {
      try {
        setIsLoading(true);
        const token = await executeRecaptcha('check_login_form_mnspf');
        let formDataSubmit = {
          ...formData,
          'g-recaptcha-response': token
        };
        const response = await LoginUserService(formDataSubmit);
        setIsLoading(false);
        setErrorForm(response.validator);
        if (
          localStorage.getItem('accessToken') &&
          localStorage.getItem('user')
        ) {
          navigate('/');
        } else if (!response.validator) {
          setErrorForm(response.message);
          setIsLoading(false);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } catch (err) {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    return (
      <div>
        <GoogleReCaptcha />
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || isLoading}
          onClick={handleSubmit}
        >
          Me connecter
        </Button>
      </div>
    );
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form>
      {errorForm && <FormAlert variant="danger" data={errorForm} />}

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Adresse email</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Adresse email' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Mot de passe</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Mot de passe' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/forgot-password?email=${formData.email}`}
          >
            Mot de passe oublié ?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <GoogleReCaptchaProvider
          reCaptchaKey="6LeCxtoZAAAAAIj8jlShvSR2896YA1mU3TSE4P2Y"
          language="fr-FR"
        >
          <ReComponent />
        </GoogleReCaptchaProvider>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginMnspf;
