import Background from 'components/common/Background';
import React from 'react';
import { Card } from 'react-bootstrap';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import PropTypes from 'prop-types';

const Greetings = () => {
  return (
    <Card className="h-100">
      <Background image={corner1} className="rounded-soft bg-card" />
      <Card.Header className="z-index-1">
        <h5 className="text-primary">Bienvenue !</h5>
        <h6 className="text-600">
          Espace de gestion du simulateur de site internet Qwenty.
        </h6>
      </Card.Header>
      {/*<Card.Body className="z-index-1">
        <Row className="g-2 h-100 align-items-end">
          <Col sm={6} md={5}>
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon="table" className="text-primary" />
              </div>
              <div className="flex-1">
                <Link
                  to="#!"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/formulas/complementaires');
                  }}
                  className="stretched-link"
                >
                  <h6 className="text-800 mb-0">Gestion des complémentaires</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">
                  Gérez les complémentaires proposées sur le site et dans les
                  simulateurs.
                </p>
              </div>
            </Flex>
          </Col>
          <Col sm={6} md={5}>
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon="layer-group" className="text-primary" />
              </div>
              <div className="flex-1">
                <Link
                  to="#!"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/simulators/mutuelle');
                  }}
                  className="stretched-link"
                >
                  <h6 className="text-800 mb-0">Trouver l'offre idéale</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">
                  Gérez les questions, les réponses et les complémentaires
                  proposées.
                </p>
              </div>
            </Flex>
          </Col>
          <Col lg={12} className="mt-3"></Col>
          <Col sm={6} md={5}>
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon="table" className="text-primary" />
              </div>
              <div className="flex-1">
                <Link
                  to="#!"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/procedures');
                  }}
                  className="stretched-link"
                >
                  <h6 className="text-800 mb-0">Gestion des couvertures</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">
                  Gérez l'ensemble des actes médicaux couverts par les
                  complémentaires.
                </p>
              </div>
            </Flex>
          </Col>
          <Col sm={6} md={5}>
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon="table" className="text-primary" />
              </div>
              <div className="flex-1">
                <Link
                  to="#!"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/procedures-examples');
                  }}
                  className="stretched-link"
                >
                  <h6 className="text-800 mb-0">Exemples du simulateur</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">
                  Gérez les exemples de soins proposés aux internautes lors de
                  leur simulation.
                </p>
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Body>*/}
    </Card>
  );
};

Greetings.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  )
};

export default Greetings;
